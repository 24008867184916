<template>
  <!--  <div v-html="innerHtml"></div>-->
  <div id="html-renderer"></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'HTMLRenderer',
  data: function () {
    return {
      innerHtml: undefined
    }
  },
  computed: {
    queryParams() {
      return new URLSearchParams(window.location.search)
    },
    resourceBlob() {
      return this.queryParams.get('blob')
    }
  },
  async mounted() {
    const request = await axios.get(this.resourceBlob, {
      responseType: 'text'
    })
    this.innerHtml = request.data
    this.createHtml()
  },
  methods: {
    createHtml() {
      let iframe = document.createElement('iframe')
      iframe.style.width = '100vw'
      iframe.style.minHeight = '100vh'
      iframe.sandbox = ''
      let html = `<body>${this.innerHtml}</body>`
      document.getElementById('html-renderer').appendChild(iframe)
      iframe.srcdoc = html
      // iframe.contentWindow.document.open()
      // iframe.contentWindow.document.write(html)
      // iframe.contentWindow.document.close()
    }
  }
}
</script>

<style scoped></style>
